import React, { useEffect, useState} from "react"
import { Row, Col, Collapse, Modal, Flex, Form, Input, Typography, Select, Spin, Button,Image, message, Tooltip,Badge ,Divider  } from "antd"
import CpfCnpj from "@phpenterprise/cpf-cnpj-mask"
import Mask from 'react-input-mask'

//componets
import Header from "../components/header"
import Footer from "../components/footer"
import {checkDoc, getWindowParams, generateToken, checkCnpjExist} from '../../helper'
import config from 'config/config'

// images
import LOGO from "../../assets/img/logo.png"
import NOVOUSUARIOSM from "../../assets/img/novo_usuario-150x150.png"
import NOVOUSUARIOLG from "../../assets/img/novo_usuario.png"

const { Title, Paragraph, Text, Link} = Typography;


const Home = (props) => {
    const [form] = Form.useForm();
    const [formSms] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)
    const [etapa, setEtapa] = useState('CADASTRO')
    const [token] = useState(generateToken())
    const [code, setCode] = useState()
    const [dataForm, setDataForm] = useState()

    useEffect(() => {
    },[])

    const timedUnloading = () => {
        setTimeout(() => {
            setLoading(false)
        },[2000])
    }

    const onFinishForm = async (e) => {

        let valid = await checkCnpjExist(e.cnpj.replace(/\D/g,''))
        if(valid !== true){
            message.error(valid)
            return false
        }


        let url = `${config[process.env.NODE_ENV].int}/sms`
        setLoading(true)
        try{
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `${token}`
                },
                body: JSON.stringify({
                    "lista": [
                        {
                            "origem": "BSFDIRETO",
                            "titulo": "Envio codigo operador",
                            "texto": "BSFDIRETO: seu codigo de verificacao e ",
                            "email": form.getFieldValue('email'),
                            "celular": form.getFieldValue('telefone').replace(/\D/g,''),
                            "type": "code"
                        }
                    ]
                })
            })

            if( !response.ok ){
                let json = await response.json()
                message.error( "Problemas na validação do Telefone, tente mais tarde.")
                timedUnloading()
                return false
            }

            let json = await response.json()
            if(json.content.group_id){
                setCode(json.content.group_id)
                setDataForm(e)
                timerResend()
                setEtapa("SMS")
                timedUnloading()
                return true
            }
            throw new Error("Problemas de comunicação com o servidor.");
            
        }catch(e){
            console.log(e)
            timedUnloading()
            message.error("Problemas de comunicação com o servidor.")
        }
    }

    const timerResend = () => {
        setResendLoading(true);
        setTimeout(() => {
            setResendLoading(false);
        }, 60000);
    }

    const resendSMS = async (e) => {
        // setDataForm(e)
        let url = `${config[process.env.NODE_ENV].int}/sms`
        setLoading(true)
        try{
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `${token}`
                },
                body: JSON.stringify({
                    "lista": [
                        {
                            "origem": "BSFDIRETO",
                            "titulo": "Envio codigo operador",
                            "texto": "BSFDIRETO: seu codigo de verificacao e ",
                            "email": form.getFieldValue('email'),
                            "celular": form.getFieldValue('telefone').replace(/\D/g,''),
                            "type": "code"
                        }
                    ]
                })
            })

            if( !response.ok ){
                let json = await response.json()
                message.error( "Problemas na validação do Telefone, tente mais tarde.")
                timedUnloading()
                return false
            }

            let json = await response.json()
            if(json.content.group_id){
                setCode(json.content.group_id)
                timerResend()
                timedUnloading()
                return true
            }
            throw new Error("Problemas de comunicação com o servidor.");
        }catch(e){
            console.log(e)
            timedUnloading()
            message.error("Problemas de comunicação com o servidor.")
        }
    }

    

    /**
     * @param {*} sms_code
     * @returns 
     */
    // let sms_code = formSms.getFieldValue(['sms_code']).replace(/\D/g,"")
    const onFinishSMS = async (e) => {
        let url = `${config[process.env.NODE_ENV].int}/sms?group_id=${code}`
        setLoading(true)
        try{
            let response = await fetch(url, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `${token}`
                },
            })

            if( !response.ok ){
                let json = await response.json()
                message.error( "Não foi possivel validar o codigo SMS")
                timedUnloading()
                return false
            }
            
            let json = await response.json()
            console.log(json.content[0].text_sms_envo.replace(/\D/g,''), e.sms_code.replace(/\D/g,''))
            if(json.content[0].text_sms_envo.replace(/\D/g,'') == e.sms_code.replace(/\D/g,'')){
                // createOperador()
                createOperador()
                timedUnloading()
                return true
            } 
            message.error( "Cosigo de validação não confere!")
            timedUnloading()
            return false
        }catch(e){
            timedUnloading()
            message.error("Problemas de comunicação com o servidor.")
        }
    }


    
    const createOperador = async () => {
        let url = `${config[process.env.NODE_ENV].int}/empresa/operador/${form.getFieldValue('cnpj').replace(/\D/g,"")}`
        setLoading(true)
        try{
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `${token}`
                },
                body: JSON.stringify({...dataForm })
            })

            if( !response.ok ){
                let json = await response.json()
                if(json.status == false){
                    throw json.message
                }
                message.error( "CNPJ ou CPF não localizado")
                timedUnloading()
                return false
            }

            let json = await response.json()
            setEtapa('SUCESSO')
            return true
        }catch(e){
            if(typeof e == 'object'){
                console.log(e)
                message.error("Falha em criar operador, caso o problema continuar entre em contato via Whatsapp")
                return false
            }
            message.error(e)
        }finally{
            timedUnloading()
        }
    }

    

    const FormOprdr = () => {
        return <Spin spinning={loading}>
            <Form
                form={form}
                onFinish={onFinishForm}
                autoComplete="off"
                initialValues={{
                    cnpj: getWindowParams().cnpj,
                    // nome: "teste",
                    // cpf: '11111111111',
                    // email: "edrobeda@gmail.com",
                    // telefone: "1998144-2580"
                    // setor: 1,
                    // password: 'A123mudar',
                    // confirm: 'A123mudar'
                }}
            >
                <Title level={2}>Preencha as informaçoes abaixo</Title>
                <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >

                    <Divider orientation="left">Dados Empresa</Divider>
                        <Form.Item
                            className="form-control"
                            name="cnpj"
                            label={ <>
                                CPF/CNPJ Empresa
                                <Tooltip placement="topLeft" title={'Empresa registrada no BSF-direto.'}>
                                    <Badge style={{marginLeft:5}} text count='?' size="small"/>
                                </Tooltip>
                            </> }
                            rules={[
                                { required: true   , message: "CNPJ é requerido."},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        let check = checkDoc(getFieldValue('cnpj'),[,'cpf','cnpj'])
                                        if ( !value || typeof check !== 'string' ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(check));
                                    },
                                }),
                            ]}
                        >
                                <CpfCnpj className="ant-input ant-input-outlined" />
                        </Form.Item>
                    </Col>
                    
                    <Divider orientation="left">Dados do Operador</Divider>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Form.Item
                            className="form-control"
                            name="nome"
                            label="Nome Completo"
                            rules={[ { required: true   , message: "Nome é requerido."}, ]}
                        >
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Form.Item
                            className="form-control"
                            name="cpf"
                            label="CPF"
                            rules={[
                                { required: true, message:"CPF é requerido."},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    let check = checkDoc(getFieldValue('cpf'),['cpf'])
                                    if ( !value || typeof check !== 'string' ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(check));
                                    },
                                }),
                            ]}
                        >
                            <CpfCnpj className="ant-input ant-input-outlined" maxLength={14} />
                        </Form.Item>
                    </Col>
                    
                
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                            className="form-control"
                            name="telefone"
                            label="Celular"
                            rules={[
                                { required: true, message:"Telefone é requerido."},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if ( !value || value.replace(/\D/g,'').length >= 11) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Numero celular inválido!'));
                                    },
                                }),
                            ]}
                        >
                            <Mask mask="(99) 99999-9999"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                            className="form-control"
                            name="email"
                            label="E-mail"
                            rules={[
                                { required: true, message:"E=mail é requerido."},
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                            className="form-control"
                            name="setor"
                            label="Setor"
                            rules={[
                                // { required: true, message:"Setor é requerido."},
                            ]}
                            >
                            <Select showSearch 
                                filterOption={(input, option) =>{
                                    if((option?.label ?? '').toLowerCase().includes(input.toLowerCase())){
                                        return option.value
                                    }
                                    // (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            }
                            options={[
                                {"value":1, "label":"Administrativo"},
                                {"value":2, "label":"Beneficio"},
                                {"value":4, "label":"Contabilidade"},
                                {"value":9, "label":"Diretor"},
                                {"value":3, "label":"Financeiro"},
                                {"value":10,"label":"Juridico"},
                                {"value":6, "label":"Responsável"},
                                {"value":5, "label":"RH"},
                                {"value":8, "label":"Sócio"}
                            ]}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                            className="form-control"
                            name="password"
                            label="Senha"
                            rules={[
                                {
                                    required: true,
                                    message: 'Informe a senha.',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if ( !value || !( getFieldValue('password').length < 8 )  ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('A senha deve ter entre 8 e 16 caracteres.'));
                                    },
                                }),
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if ( !value || !( getFieldValue('password').length > 16 )  ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('A senha deve ter entre 8 e 16 caracteres.'));
                                    },
                                }),
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    let regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/
                                    if ( !value || ( getFieldValue('password').length < 8 ) || (regex.test(getFieldValue('password'))) ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('A senha deve conter numeros, letras maiusculas e minusculas '));
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                    
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>

                        <Form.Item
                            className="form-control"
                            name="confirm"
                            label="Confirmar"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Confirme sua senha.',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('A confirmação não confere!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Form.Item className="text-right form-control" >
                            <Button htmlType="submit">Enviar</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form> 
        </Spin>
    }

    const CheckSMS = () => {
        

        return <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                <Form
                    form={formSms}
                    onFinish={onFinishSMS}
                    autoComplete="off"
                    initialValues={{
                        // sms_code: '123123'
                    }}
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                            <Input className="" name="id" type="hidden" value="id0"/> 
                            <Input className="sms_verify id0" name="sms_verify" type="hidden"/> 
                            <Input className="sms_protocolo" name="protocolo" type="hidden"/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                            <Title level={4} align="center">
                                Para confirmar o seu cadastro, informe abaixo o código que foi enviado.
                            </Title>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                            <Paragraph>
                                Essa validação pode demorar alguns segundos até o recebimento.
                            </Paragraph>
                            <Paragraph>
                                Enviamos o código de ativação no seu celular: <b><span id="sms_cell_mask"></span></b><br/>
                            </Paragraph>
                        </Col>
                        <Col push={6} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
                            <Form.Item
                                className="form-control"
                                name="sms_code"
                                rules={[
                                    // CÓDIGO INVÁLIDO
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            let check = getFieldValue('sms_code').replace(/\D/g,"")
                                            if ( check.length !== 6) {
                                                return Promise.reject(new Error('CÓDIGO INVÁLIDO'));
                                            }
                                            return Promise.resolve();
                                        }
                                    }),
                                ]}
                            >
                                <Mask mask="9 9 9 - 9 9 9" style={{textAlign:"center",fontSize: 30}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center " }>
                            <Form.Item className="form-control">
                                <Button htmlType="submit">Enviar</Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center " }>
                            <Divider/>
                            <Form.Item className="form-control" >
                                    <Button loading={resendLoading} type="button" onClick={() => resendSMS()}>Reenviar</Button>
                            </Form.Item>
                            <Form.Item className="form-control" >
                                <Button type="button" onClick={() => { formSms.resetFields(); setResendLoading(false); setEtapa('CADASTRO') }}>Voltar ao cadastro</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>


            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <div className="tryChangeYourCel" style={{ display: 'none', color: 'darkred'}} bis_skin_checked="1">
                    Notamos que foram solicitadas várias vezes o reenvio do código de ativação, porém sem resultados,
                    se estiver com problemas em validar e/ou preferir solicite a troca do celular e tente novamente.
                </div>
            </Col>
        </Row>
    }

    const SucessoReturn = () => {
        return <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{textAlign:"center"}}>
                <Title level={3} align="left">
                    Operador cadastrado com sucesso!
                </Title>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign:"center"}}>
                <Title level={4} align="left">
                Seu usuário foi cadastrado com sucesso. Agora, por favor, efetue o login utilizando o formulário localizado na parte superior da página.
                </Title>
                <Title level={4} align="left">
                    <Link href="/">
                        <Button htmlType="button">Voltar a página principal</Button>
                    </Link>
                </Title>
            </Col>
        </Row>
    }

    
    return <>
        <Header/>
        <main>
            {/* ICONES COM BENEFICIOS */}
            <section className="cadastro">
                <div className="wrapper">
                    <h1 className="title small">
                        CADASTRAR NOVO USUÁRIO
                    </h1>
                    <Spin spinning={loading}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="animated fadeIn">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{textAlign:"center"}}>
                                        <Image
                                            width={150}
                                            src={NOVOUSUARIOSM}
                                            preview={false}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{textAlign:"center"}}>
                                        <Title level={4} align="left">
                                            Para maior segurança nas transações de dados em nosso site, estamos disponibilizando o sistema Acesso Protegido.
                                        </Title>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign:"center"}}>
                                        <Title level={4} align="left">
                                            Com esse tipo de acesso, apenas usuários previamente cadastrados poderão visualizar e gerenciar as informações.
                                        </Title>
                                        <Title level={4} align="left">
                                            Para prosseguir com o acesso cadastre o usuário,
                                        </Title>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign:"center"}}>
                                        <Divider/>
                                        <Title level={4} align="left">
                                            Para retornar a página principal clique em voltar
                                        </Title>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign:"right"}}>
                                        <Link href="/"><Button htmlType="button" type="primary">VOLTAR</Button></Link>
                                    </Col>
                                </Row>
                            </Col>
                            
                            {etapa == "CADASTRO" ? 
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className={"animated fadeIn"}>
                                    <FormOprdr/>
                                </Col>
                            : ""}
                            {etapa == "SMS" ? 
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="">
                                    <CheckSMS/>
                                </Col>
                            : ""}
                            {etapa == "SUCESSO" ? 
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="">
                                    <SucessoReturn/>
                                </Col>
                            : ""}
                        </Row>
                    </Spin>
                </div>
            </section>
        </main>
        <Footer/>
    </>
}

export default Home