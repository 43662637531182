import React, { useEffect, useState} from "react"
import { Row, Col, Collapse, Modal, Flex } from "antd"
//componets
import Header from "../components/header"
import Footer from "../components/footer"

// images
import LOGO from "../../assets/img/logo.png"
// icones
import FARMACIA from "../../assets/img/remedios-150x150.jpg"
import DINHEIRO from "../../assets/img/dinheiro-150x150.jpg"
import CEREBRO from "../../assets/img/cerebro-150x150.jpg"
import NUTRICIO from "../../assets/img/nutricio-150x150.jpg"
import SALARIO from "../../assets/img/salario-150x150.jpg"
import ODONTO from "../../assets/img/odonto-150x150.jpg"
import MEDICO from "../../assets/img/medico-150x150.jpg"
import GESTANTE from "../../assets/img/gestante-150x150.jpg"
import VIDA from "../../assets/img/vida-150x150.jpg"
import DESCONTO from "../../assets/img/desconto-150x150.jpg"

const Home = () => {
    const items = [
        {
            key: '1',
            label: 'Medicina e Segurança do trabalho',
            children: <p>Disponibiliza sem custos para matriz ou sede da empresa, o PCMSO, exames clínicos – ASO, suporte ao setor jurídico, médico responsável, relatório anual no modelo E- Social, envio do arquivo XML ao e-Social e arquivamento da documentação por 20 anos. Os demais serviços ganham descontos significativos, assim como os exames complementares, PGR, LTCAT e outros laudos técnicos exigidos pelas Normas Regulamentadoras do M.T.E.</p>,
        },
        {
            key: '2',
            label: 'Entrega do e-Social',
            children: <p>Auditoria e envio envio dos arquivos XML do eSocial.</p>,
        },
        {
            key: '3',
            label: 'Serviços digitais',
            children: <p>Assinatura Digital, Certificação Digital.</p>,
        },
        {
            key: '4',
            label: 'Registro de Ponto Remoto',
            children: <p>Sistema que facilita o controle de presença dos colaboradores internos ou externos.</p>,
        },
        {
            key: '5',
            label: 'Triagem de Atestado',
            children: <p>Blinda sua empresa de falsos atestados e fomenta uma cultura de honestidade e transparência entre os demais colaboradores.</p>,
        },
        {
            key: '6',
            label: 'Folha de pagamento virtual',
            children: <p>Acesso ao sistema online de pagamentos que agiliza, desburocratiza e reduz os custos operacionais no envio das remunerações aos colaboradores.</p>,
        },
        {
            key: '7',
            label: 'Conecta Empresa',
            children: <p>Aplicativo que facilita a comunicação entre a empresa e seus colaboradores.</p>,
        },
        {
            key: '8',
            label: 'Mural de Empregos',
            children: <p>Facilita o preenchimento de vagas disponíveis na empresa.</p>,
        },
        {
            key: '9',
            label: 'Lista de fornecedores',
            children: <p>Rede de fornecedores para adquirir serviços e produtos com muitas vantagens em relação aos seus concorrentes.</p>,
        },
        {
            key: '10',
            label: 'Cursos CIPA e Segurança do Trabalho On-line',
            children: <p>Cursos CIPA e Segurança do Trabalho On-line.</p>,
        },
        {
            key: '11',
            label: 'Vida em Grupo',
            children: <p>Apólice de Seguro Bronze.</p>,
        },
        {
            key: '12',
            label: 'Recolocação',
            children: <p>Trabalhador terá acesso a uma grande rede de vagas disponíveis.</p>,
        }
    ]

    return <>
        <Header/>
        <main>
            {/* TOPO LOGO E VIDEO */}
            <section className="topo">
                <div className="wrapper">
                    
                    <div className="center">
                        <img className="logo" src={LOGO} />
                    </div>
                   
                    <Row>
                        <Col sm={24}>
                            <h1 className="title">
                                Sua empresa poderia estar pagando valores <br/><span>muito abaixo da média de mercado</span>
                            </h1>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={24}>
                            <h3 className="destaque">Sem prejudicar qualidade, cortar funcionários ou serviços</h3>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="video">
                <div className="video-wrapper">
                    <div className="white-ghost">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/eooEjnJARTk?si=PruiLIaaqewbL_mL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    
                        <a className="btn" target="_blank" href="https://beneficiosocial.s3.sa-east-1.amazonaws.com/tabela-site.pdf">Veja o quanto pode economizar</a>
                    </div>
                </div>
            </section>

            {/* TEXTO DIFERENÇAS */}
            <section className="diferente">
                <div className="wrapper">
                    <Row justify={"end"}>
                        <Col sm={24} md={15} lg={15}>
                            <h1 className="title small">
                                Descubra porque a <span>BSF é diferente</span>
                            </h1>

                            <p>Há mais de duas décadas no mercado, a BSF se destaca por oferecer serviços de alta qualidade a preços imbatíveis.</p>
                            <p>Essa conquista é fruto de nossa expertise em fechar <b>parcerias estratégicas</b> e <b>negociar em grande escala</b>.</p>
                        </Col>
                    </Row>
                </div>
            </section>

            {/* UM COLAPSE DE GANHOS */}
            <section className="ganho">
                <div className="wrapper">
                    <Row>
                        <Col sm={24}>
                            <h1 className="title small">
                                E o que sua empresa <br/><span>ganha com isso?</span>
                            </h1>
                            <p className="sub-title">
                                Nós repassamos os descontos diretamente para você <b>economizar <br/>muito</b> em despesas básicas como:
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={24}>
                            <Collapse size="middle" className="collapse-main" items={items} defaultActiveKey={['1']} />
                        </Col>
                    </Row>

                </div>
            </section>

            {/* ICONES COM BENEFICIOS */}
            <section className="beneficios">
                <div className="wrapper">
                    <p className="nao-isso">E não é só isso…</p>
                    <h1 className="title small">
                        Seus funcionários também desfrutam de benefícios que vão <span>além do básico</span>
                    </h1>

                    <Flex className={"linha1"} align="start">
                        <Flex className="lista-flex" gap={20} style={{width: '100%'}} justify={"stretch"} align={'flex-start'}>
                            <div className="icon-box">
                                <div className="icon">
                                    <img src={FARMACIA} />
                                </div>
                                <div className="titulo">
                                    <b>Farmácia</b> para todos
                                </div>
                                <div className="texto">
                                    Desconto em medicamentos em farmácias credenciadas.
                                </div>
                            </div>

                            <div className="icon-box">
                                <div className="icon">
                                    <img src={DINHEIRO} />
                                </div>
                                <div className="titulo">
                                    <b>Conta</b> corrente Virtual
                                </div>
                                <div className="texto">
                                    Zero tarifas e taxas.
                                </div>
                            </div>

                            <div className="icon-box">
                                <div className="icon">
                                    <img src={CEREBRO} />
                                </div>
                                <div className="titulo">
                                    <b>Apoio</b> Psicológico
                                </div>
                                <div className="texto">
                                    Terapia on-line individual para seus colaboradores.
                                </div>
                            </div>

                            <div className="icon-box">
                                <div className="icon">
                                    <img src={NUTRICIO} />
                                </div>
                                <div className="titulo">
                                    <b>Apoio</b> Nutricional
                                </div>
                                <div className="texto">
                                    Consulta on-line individual para seus colaboradores.
                                </div>
                            </div>

                            <div className="icon-box">
                                <div className="icon">
                                    <img src={SALARIO} />
                                </div>
                                <div className="titulo">
                                    <b>Vale</b> Emergencial
                                </div>
                                <div className="texto">
                                    Antecipação salarial com juros abaixo do mercado.
                                </div>
                            </div>
                        </Flex>
                    </Flex>

                    <Flex className="linha2" align="start" vertical>
                        <Flex className="lista-flex" gap={20} style={{width: '100%'}} justify={"stretch"} align={'flex-start'}>
                            <div className="icon-box">
                                <div className="icon">
                                    <img src={ODONTO} />
                                </div>
                                <div className="titulo">
                                    <b>Odontológico</b> Flex
                                </div>
                                <div className="texto">
                                    Atendimento odontológico fundamental e emergencial ao trabalhador.
                                </div>
                            </div>

                            <div className="icon-box">
                                <div className="icon">
                                    <img src={MEDICO} />
                                </div>
                                <div className="titulo">
                                    <b>Consulta</b> Médica Online
                                </div>
                                <div className="texto">
                                    Para os trabalhadores, familiares e pessoas de seu relacionamento.
                                </div>
                            </div>

                            <div className="icon-box">
                                <div className="icon">
                                    <img src={GESTANTE} />
                                </div>
                                <div className="titulo">
                                    <b>Consulta</b> médica online gestante
                                </div>
                                <div className="texto">
                                    Inclui também apoio nutricional e psicológico.
                                </div>
                            </div>

                            <div className="icon-box">
                                <div className="icon">
                                    <img src={VIDA} />
                                </div>
                                <div className="titulo">
                                    <b>Apoio</b> Social
                                </div>
                                <div className="texto">
                                    Assistente Social disponível para os trabalhadores do segmento
                                </div>
                            </div>

                            <div className="icon-box">
                                <div className="icon">
                                    <img src={DESCONTO} />
                                </div>
                                <div className="titulo">
                                    <b>Clube</b> de descontos
                                </div>
                                <div className="texto">
                                    Desconto em mais de 30 mil estabelecimentos, que inclui restaurantes, farmácias, sites, instituições de ensino, academias, entretenimento, viagens, higienização, lavanderia, fotografia, organizações financeiras.
                                </div>
                            </div>
                        </Flex>
                    </Flex>

                </div>

                <center style={{ marginTop: 100}}>
                    <a className="btn" target="_blank" href="http://wa.me/5519998606040">Quero Economizar</a>
                </center>
            </section>
        </main>
        <Footer/>
    </>
}

export default Home